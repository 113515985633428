import React from "react";
import Icon from "components/ui/Icon";
import { getUserProfile } from "store/api/apiSlice";
import { MarqetaIds } from "../MarqetaContainer";
import * as Styled from "./styled";

interface Props {
  ids: MarqetaIds;
  hideDetails: () => void;
  detailsAreShown: boolean;
  isActive: boolean;
}

const CardBack: React.FC<Props> = ({
  hideDetails,
  ids,
  detailsAreShown,
  isActive,
}) => {
  const { data: user } = getUserProfile();

  return (
    <Styled.Wrapper $isActive={isActive}>
      <Styled.CardDetails>
        <Styled.DetailsHeading caps>Virtual Reset card</Styled.DetailsHeading>
        <Styled.DetailsRow>
          {user ? `${user.firstName} ${user.lastName}` : ""}
        </Styled.DetailsRow>
        <Styled.DetailsRow>
          {detailsAreShown && <Styled.CardPan id={ids.cardPan} />}
        </Styled.DetailsRow>
        <Styled.DetailsRow>
          <Styled.DetailsItem>
            <Styled.ItemLabel>Exp</Styled.ItemLabel>
            {detailsAreShown && <Styled.CardExp id={ids.cardExp} />}
          </Styled.DetailsItem>
          <Styled.DetailsItem>
            <Styled.ItemLabel>CVV</Styled.ItemLabel>
            {detailsAreShown && <Styled.CardCvv id={ids.cardCvv} />}
          </Styled.DetailsItem>
        </Styled.DetailsRow>
      </Styled.CardDetails>

      <Styled.BackButton onClick={hideDetails}>
        <Icon name="arrowLeft" />
      </Styled.BackButton>
    </Styled.Wrapper>
  );
};

export default CardBack;
