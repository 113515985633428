import React, { useRef, useState } from "react";
import Button from "components/ui/Button";
import CenteredLayout from "components/layout/CenteredLayout";
import {
  Field,
  FormMessage,
  FormSection,
  Input,
  PasswordStrength,
} from "components/form";
import { Heading } from "components/ui/Text";
import * as Styled from "./styled";
import PasswordStrengthContainer from "./PasswordStrengthContainer";

interface CurrentUser {
  first_name: string;
  last_name: string;
}

interface Props {
  currentUser: CurrentUser;
  errors: {
    [key: string]: string[];
  };
}

const prettifyMessage = (message: string) => {
  let formattedMessage;
  switch (message) {
    case "doesn't match Password":
      formattedMessage = "Passwords must match";
      break;
    default:
      formattedMessage = `Password ${message}`;
  }
  if (formattedMessage.charAt(formattedMessage.length - 1) !== ".") {
    formattedMessage += ".";
  }
  return formattedMessage;
};

const PasswordFields: React.FC<Props> = ({ currentUser, errors }) => {
  const [hasError, setHasError] = useState(false);
  const inputRef = useRef(null);
  const [password, setPassword] = useState("");
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const showError = () => {
    setHasError(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const rawErrorMessages = Object.values(errors || {}).flat();
  const errorMessage = rawErrorMessages
    .map((message) => prettifyMessage(message))
    .join(" ");

  return (
    <CenteredLayout>
      <Styled.Wrapper>
        <Styled.FormHeader>
          <Styled.FormTitle tag="h1">
            Let's get you started with&nbsp;Reset!
          </Styled.FormTitle>
        </Styled.FormHeader>

        {errorMessage.length > 0 && <FormMessage>{errorMessage}</FormMessage>}

        <FormSection>
          <Heading.H3 tag="h2">Set your password</Heading.H3>

          <PasswordStrengthContainer
            password={password}
            blacklist={[currentUser?.first_name, currentUser?.last_name]}
          >
            {({ strength }) => (
              <>
                {hasError && strength.score < 4 && (
                  <FormMessage>
                    <div>
                      You must choose a <strong>strong</strong> password. Please
                      see requirements below.
                    </div>
                  </FormMessage>
                )}
                <Field name="user[password]">
                  <Input
                    name="user[password]"
                    placeholder="Password"
                    type="password"
                    required
                    onChange={onChangePassword}
                    autoComplete="new-password"
                    ref={inputRef}
                  />

                  {password.length > 0 && <PasswordStrength {...strength} />}
                </Field>

                <Field name="user[password]">
                  <Input
                    name="user[password_confirmation]"
                    placeholder="Confirm password"
                    type="password"
                    required
                  />
                </Field>
                {strength.score === 4 ? (
                  <Button type="submit" data-test="btn-submit">
                    Set Password
                  </Button>
                ) : (
                  <Button type="button" onClick={showError}>
                    Set Password
                  </Button>
                )}
              </>
            )}
          </PasswordStrengthContainer>
        </FormSection>
      </Styled.Wrapper>
    </CenteredLayout>
  );
};

export default PasswordFields;
