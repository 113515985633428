import { session } from "utils/storage";
import { SignupSelections } from "types/general";
import { getSessionPartner } from "utils/partners";
import { Eligibility, PinwheelProduct } from "./types";

export const supports = (requiredProducts, products) =>
  requiredProducts.every((p) => products.includes(p)) ? "yes" : "no";

export const supportsPaystubsDds = ({ products }) =>
  supports([PinwheelProduct.paystubs, PinwheelProduct.dds], products);

export const supportsIncomeEmployment = ({ products }) =>
  supports([PinwheelProduct.income, PinwheelProduct.employment], products);

export const supportsShifts = ({ products }) =>
  supports([PinwheelProduct.shifts], products);

export const supportsAll = ({ products }) =>
  supports(
    [
      PinwheelProduct.paystubs,
      PinwheelProduct.shifts,
      PinwheelProduct.employment,
      PinwheelProduct.income,
      PinwheelProduct.dds,
    ],
    products
  );

export const markEligible = () => Eligibility.eligible;
export const markIneligible = () => Eligibility.ineligible;

export const CONDITIONS = {
  root: ({ products }) => (products ? "yes" : "no"),

  // Has user selected a provider?
  "root.no": () => Eligibility.indeterminate,
  "root.yes": ({ getValues }) => getValues("questionnaire[incomeType]"), // hourly, gig, salary, benefits

  // What type of income?
  // Gig
  "root.yes.gig": supportsPaystubsDds,
  // Does it support paystubs or DDS?
  "root.yes.gig.no": markIneligible,
  "root.yes.gig.yes": supportsShifts,
  // Does it support shifts?
  "root.yes.gig.yes.no": markIneligible,
  "root.yes.gig.yes.yes": markEligible,

  // Salary
  "root.yes.salary": supportsPaystubsDds,
  // Does it support paystubs or DDS?
  "root.yes.salary.no": markIneligible,
  "root.yes.salary.yes": supportsIncomeEmployment,
  // Does it support income and employment?
  "root.yes.salary.yes.no": markIneligible,
  "root.yes.salary.yes.yes": markEligible,

  // Benefits
  "root.yes.benefits": supportsPaystubsDds,
  // Does it support paystubs or DDS?
  "root.yes.benefits.no": markIneligible,
  "root.yes.benefits.yes": supportsIncomeEmployment,
  // Does it support income and employment?
  "root.yes.benefits.yes.no": markIneligible,
  "root.yes.benefits.yes.yes": markEligible,

  // Hourly
  "root.yes.hourly": ({ getValues }) =>
    getValues("questionnaire[tracksHoursWithPayroll]"),
  // Do you track your hours in your payroll provider?
  "root.yes.hourly.no": markIneligible,
  "root.yes.hourly.yes": supportsAll,
  // Does it support paystubs, shifts, income, employment and DDS?
  "root.yes.hourly.yes.no": markIneligible,
  "root.yes.hourly.yes.yes": markEligible,
};

export const pathIncludes = (path, subPath) =>
  path.join(".").indexOf(subPath) === 0;

export const getInitialParams = () => {
  const signupParams = session.getItem("signupParams");
  const partner = getSessionPartner();

  return (partner?.signup || signupParams || {}) as SignupSelections;
};
