import { FormTaskStatus } from "types/task";

export enum TaskName {
  signup = "signup",
  payrollLinking = "payroll_linking",
  bankLinking = "bank_linking",
}

export interface Task {
  name: TaskName;
  state: FormTaskStatus;
}
