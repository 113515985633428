export enum FormTaskStatus {
  incomplete = "incomplete",
  ready = "ready",
  complete = "complete",
}

export interface FormTask {
  id: string;
  title: string;
  description?: React.ReactNode;
  status: FormTaskStatus;
}
