import { removeAllCookies } from "utils/cookie";
import environment from "utils/environment";
import { Address, AddressRequest } from "./address";
import { ApiCardAccount } from "./cardAccount";

export enum RepaymentMethod {
  directDepositFlow = "direct_deposit_flow",
  achDebitFlow = "ach_debit_flow",
}

export interface ApiUserProfile {
  id: string;
  firstName: string | null;
  lastName: string | null;
  createdAt: string;
  email: string;
  phoneNumber?: string;
  currentAddress?: Address;
  currentCardAccount?: ApiCardAccount;
  mfaEnabled: boolean;
  admin?: boolean;
  impersonating?: boolean;
  eligibleForCard: boolean;
  enableProbationaryLimit: boolean;
  repaymentMethod: RepaymentMethod;
  partnerSlug?: string;
  partnerLogo?: {
    // currently only for mock API customizations
    data: string;
    type: string;
  };
}

export interface ApiPersonalInfosRequest {
  phoneNumber: string;
  dateOfBirth: string;
  ssn: string;
  address: AddressRequest;
}

export const logout = async () => {
  const res = await fetch("/users/sign_out", {
    credentials: "include",
  });
  if ([200, 204].includes(res.status)) {
    removeAllCookies();

    if (!environment.enableDemoTriggers) {
      localStorage.clear();
      sessionStorage.clear();
    }

    window.location.pathname = "/";
  }
};
