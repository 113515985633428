import styled from "styled-components";
import { borderRadius, colors, mediaMax, shadows, zIndex } from "utils/theme";

export const ChatContainer = styled.div`
  position: absolute;

  top: calc(100% + 16px);
  right: 0;

  iframe {
    position: relative;
    z-index: 1;

    width: 360px;
    max-width: 100%;
    height: 540px;
    max-height: 100vh;

    border: 0;
    border-radius: ${borderRadius.large}px;
    box-shadow:
      ${shadows.tile},
      0 4px 24px ${colors.violet}22;
  }

  ${mediaMax("md")} {
    width: 100%;
    padding: 0 16px;

    iframe {
      width: 100%;
      height: calc(100vh - 160px);
    }
  }
`;

export const ButtonContainer = styled.div<{ $isOpen: boolean }>`
  ${mediaMax("md")} {
    ${(props) =>
      props.$isOpen &&
      `
    position: fixed;
    top: 12px;
    left: 50%;
    transform: translateX(-50%);
    z-index: ${zIndex.modal};

    display: flex;
    width: 100%;
    max-width: 576px;
    padding: 0 12px;
    justify-content: flex-end;

    &::before {
      content: '';

      position: fixed;
      top: -12px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;

      display: block;
      width: 100vw;
      height: 100vh;

      background: ${colors.midnight}80;
    }
  `}
  }
`;
