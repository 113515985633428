import React from "react";
import { useParams } from "react-router-dom";
import Loading from "components/ui/Loading";
import { getAdminUser } from "store/admin/api/apiSlice";
import * as Styled from "./styled";
import Expenses from "./Expenses";
import Danger from "./Danger";
import BasicInfo from "./BasicInfo";
import Payroll from "./Payroll";
import DirectDeposit from "./DirectDeposit";
import UnresolvedIntegrationErrors from "./UnresolvedIntegrationErrors";
import LinkedBankAccount from "./LinkedBankAccount";

const User = () => {
  const { id } = useParams();
  const { data: user, isLoading, refetch: refetchUser } = getAdminUser(id);

  if (isLoading) return <Loading />;

  if (!user) {
    window.location.replace("/admin/users");
    return null;
  }

  const { safeToSpendComponents, recurringExpenses, lastPaystub } = user || {};

  return (
    <Styled.Wrapper>
      <BasicInfo user={user} safeToSpendComponents={safeToSpendComponents} />

      <Styled.SectionGroup>
        <Payroll
          safeToSpendComponents={safeToSpendComponents}
          lastPaystub={lastPaystub}
          userId={id}
        />

        <DirectDeposit userId={id} />
      </Styled.SectionGroup>

      <LinkedBankAccount user={user} />

      <Expenses expenses={recurringExpenses} />

      <UnresolvedIntegrationErrors user={user} />

      {!user.admin && <Danger user={user} refetchUser={refetchUser} />}
    </Styled.Wrapper>
  );
};

export default User;
