import React from "react";
import styled from "styled-components";
import Logo from "components/ui/Logo";
import { PARTNERS } from "utils/partners/constants";
import { colors } from "utils/theme";
import logomark from "assets/images/general/logomark.svg";

const ResetLogo = styled(Logo)`
  display: block;
  width: 108px;
`;

const Divider = styled.div<{ $reverse?: boolean }>`
  height: 100%;
  width: 1px;

  background: ${(props) => (props.$reverse ? colors.flax : colors.midnight)};
  opacity: 0.33;
`;

const LogomarkWrapper = styled.div`
  height: 100%;
  padding: 2px 0;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  gap: 16px;

  img {
    height: 100%;
  }

  & > * {
    flex: 0 0 auto;
  }
`;

interface Props {
  partnerSlug?: string;
  reverse?: boolean;
  partnerLogo?: {
    data: string;
    type: string;
  };
}

const PartnerLogoLockup: React.FC<Props> = ({
  reverse,
  partnerSlug,
  partnerLogo,
  ...props
}) => {
  const partner = PARTNERS[partnerSlug];

  if (!partner && !partnerLogo) return <ResetLogo />;

  return (
    <Wrapper {...props}>
      <LogomarkWrapper>
        <img src={logomark} />
      </LogomarkWrapper>
      <Divider $reverse={reverse} />
      {partner ? (
        <img src={reverse ? partner.logo.reverse : partner.logo.color} />
      ) : (
        <img
          src={`data:${partnerLogo.type};charset=utf-8;base64,${partnerLogo.data}`}
        />
      )}
    </Wrapper>
  );
};

export default PartnerLogoLockup;
