import { IncomeType } from "./types";

export const DEFAULT_VALUES = {
  user: {
    first_name: "",
    last_name: "",
    email: "",
  },
  questionnaire: {
    incomeType: null,
    tracksHoursWithPayroll: null,
  },
};

export const INCOME_OPTIONS = [
  { name: "Hourly", value: IncomeType.hourly },
  { name: "Gig", value: IncomeType.gig },
  { name: "Salary", value: IncomeType.salary },
  { name: "Benefits", value: IncomeType.benefits },
];
