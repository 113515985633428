import React, { useEffect, useState } from "react";
import zxcvbn from "zxcvbn";

export const TERM = ["None", "Weak", "Weak", "Fair", "Strong"];
export const MIN_LENGTH = 12;
export const MUST_INCLUDE = [
  /[A-Z]/,
  /[a-z]/,
  /[0-9]/,
  /[~`!@#$%^&*()\-_+={}[\]|\\;:"<>,./?]/,
];
export const MUST_NOT_INCLUDE = [/[0-9]{9}/];

export const getLabel = (score: number) => TERM[score] || TERM[0];

interface Result {
  score?: number;
  feedback: {
    suggestions: string[];
  };
}

interface Strength {
  score: number;
  message: string[];
  label: string;
}

interface PassworthStrengthApi {
  strength: Strength;
}

interface Props {
  password: string;
  blacklist: string[];
  children: (props: PassworthStrengthApi) => React.ReactNode;
}

const PasswordStrengthContainer: React.FC<Props> = ({
  password,
  blacklist,
  children,
}) => {
  const [strength, setStrength] = useState<Strength>({
    score: 0,
    message: [],
    label: getLabel(0),
  });

  useEffect(() => {
    const result: Result = zxcvbn(password, blacklist);
    const baseScore = password.length > 0 ? result.score || 1 : 0;

    const mustNotInclude = [
      ...MUST_NOT_INCLUDE,
      ...blacklist.map((item) => new RegExp(item, "i")),
    ];
    const hasBlacklistedInfo = mustNotInclude.some((pattern) =>
      pattern.test(password)
    );
    const meetsOtherRequirements = MUST_INCLUDE.every((pattern) =>
      pattern.test(password)
    );
    const isLongEnough = password.length >= MIN_LENGTH;
    let altScore = 3;
    if (hasBlacklistedInfo) {
      altScore = 2;
    } else if (isLongEnough && meetsOtherRequirements) {
      altScore = 4;
    }

    let message = result.feedback.suggestions;
    if (hasBlacklistedInfo) {
      message = [
        "Your password must not include your name or other potentially identifying information, such as long strings of numbers.",
      ];
    } else if (password.length === 0) {
      message = [];
    } else if (!isLongEnough || !meetsOtherRequirements) {
      message = [];
      if (!isLongEnough) {
        message.push(
          `Your password must be at least ${MIN_LENGTH} characters.`
        );
      } else {
        message.push(
          "You must include at least one uppercase and lowercase letter, a number, and a special character (?, !, ^, etc.)"
        );
      }
    }

    const score = Math.min(baseScore, altScore);

    if (message.length === 0 && score < 4) {
      message = [
        "Avoid using common phrases or names. Try adding more characters or using a strong password generator.",
      ];
    }

    setStrength({ score, message, label: getLabel(score) });
  }, [password]);

  return children({ strength });
};

export default PasswordStrengthContainer;
