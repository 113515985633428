import { HeroType } from "types/cms";
import {
  buildCta,
  getBody,
  getHeading,
  mapImagePositionToType,
  mapTextSizeToType,
} from "./common";

const mapContentTypeToHeroType = (contentType) => {
  switch (contentType) {
    case "CardHero":
      return HeroType.card;
    case "CenteredHero":
      return HeroType.centered;
    default:
      return contentType;
  }
};

const mapThemeToType = (theme) => {
  switch (theme) {
    case "Gradient":
      return "default";
    case "Light":
      return "light";
    default:
      return theme.toLowerCase();
  }
};

const getCommonHeroData = (hero) => ({
  eyebrow: hero.eyebrow,
  heading: getHeading(hero.heading),
  body: getBody(hero.body),
  textSize: mapTextSizeToType(hero.textSize),
  cta: buildCta(hero.cta),
  theme: mapThemeToType(hero.theme),
});

const buildCardHero = (hero) => ({
  type: mapContentTypeToHeroType(hero.typename),
  data: {
    ...getCommonHeroData(hero),
    textSize: mapTextSizeToType(hero.textSize),
    image: hero.image?.url,
    imageOrientation: mapImagePositionToType(hero.imagePosition),
    cta: buildCta(hero.cta),
    theme: mapThemeToType(hero.theme),
  },
});

const buildCenteredHero = (hero) => ({
  type: mapContentTypeToHeroType(hero.typename),
  data: {
    ...getCommonHeroData(hero),
    image: hero.image?.url,
    imageOrientation: mapImagePositionToType(hero.imagePosition),
  },
});

export const buildHero = (hero) => {
  switch (hero.typename) {
    case "CardHero":
      return buildCardHero(hero);
    case "CenteredHero":
      return buildCenteredHero(hero);
    default:
      return undefined;
  }
};
