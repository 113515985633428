import { RepaymentMethod } from "utils/api/user";
import { AccountAction } from "./types";

export const getActionNoun = (action: AccountAction) => {
  switch (action) {
    case AccountAction.disconnect:
      return "disconnection";
    case AccountAction.delete:
      return "account closure";
    default:
      return action;
  }
};

export const getActionVerb = (action: AccountAction) => {
  switch (action) {
    case AccountAction.disconnect:
      return "disconnect";
    case AccountAction.delete:
      return "close";
    default:
      return action;
  }
};

export const getPlainDescription = (repaymentMethod: RepaymentMethod) => {
  switch (repaymentMethod) {
    case RepaymentMethod.achDebitFlow:
      return "disconnecting your payroll and checking accounts";
    case RepaymentMethod.directDepositFlow:
    default:
      return "(i) disconnecting your payroll and checking accounts and (ii) the closure of the Reset deposit account used to handle repayment of your advances";
  }
};

export const getDisconnectionDescription = ({
  hasCard,
  repaymentMethod,
}: {
  hasCard: boolean;
  repaymentMethod: RepaymentMethod;
}) => {
  if (!hasCard) return "";

  switch (repaymentMethod) {
    case RepaymentMethod.achDebitFlow:
      return ", and your Reset card will be frozen";
    case RepaymentMethod.directDepositFlow:
    default:
      return ", your Reset deposit account will be closed, and your Reset card will be frozen";
  }
};

export const getClosureDescription = ({
  hasCard,
  repaymentMethod,
}: {
  hasCard: boolean;
  repaymentMethod: RepaymentMethod;
}) => {
  if (!hasCard) return "";

  switch (repaymentMethod) {
    case RepaymentMethod.achDebitFlow:
      return ", and your Reset card and Reset account will be closed";
    case RepaymentMethod.directDepositFlow:
    default:
      return ", and your Reset deposit account, Reset card, and Reset account will be closed";
  }
};
