import React from "react";
import { ApiUserProfile } from "utils/api/user";
import * as Styled from "./styled";
import Greeting from "./components/Greeting";
import ChatButton from "./components/ChatButton";

interface Props {
  userProfile: ApiUserProfile;
}

const Toolbar: React.FC<Props> = ({ userProfile }) => (
  <Styled.Wrapper>
    <Greeting name={userProfile.firstName} />

    <Styled.Actions>
      <ChatButton />
    </Styled.Actions>
  </Styled.Wrapper>
);

export default Toolbar;
