import React, { useRef } from "react";
import { Heading } from "components/ui/Text";
import UILabel from "components/ui/UILabel";
import Icon from "components/ui/Icon";
import { ApiAdminUser } from "utils/api/admin/user";
import Button, { ButtonLevel } from "components/ui/Button";
import {
  usePostAdminUserDestroyMutation,
  usePostAdminUserDisconnectMutation,
  usePostAdminUserImpersonateMutation,
  usePostAdminUserVerifyIdentityMutation,
} from "store/admin/api/apiSlice";
import ConfirmActionWithValidationModal from "components/modal/ConfirmActionWithValidationModal";
import { ModalApi } from "components/modal/Modal";
import ShipCardModal from "components/modal/ShipCardModal";
import * as Styled from "./styled";

interface Props {
  user: ApiAdminUser;
  refetchUser: () => void;
}

const Danger: React.FC<Props> = ({ user, refetchUser }) => {
  const disconnectUserModalRef = useRef<ModalApi>(null);
  const deleteUserModalRef = useRef<ModalApi>(null);
  const verifyUserIdentityModalRef = useRef<ModalApi>(null);
  const shipCardModalRef = useRef<ModalApi>(null);
  const [postDisconnectUser] = usePostAdminUserDisconnectMutation();
  const [postDestroyUser] = usePostAdminUserDestroyMutation();
  const [verifyIdentity] = usePostAdminUserVerifyIdentityMutation();

  const [postImpersonateUser] = usePostAdminUserImpersonateMutation();
  const impersonateUser = async () => {
    await postImpersonateUser(user.id);
    window.location.replace("/dashboard");
  };

  const disconnectUser = async () => {
    await postDisconnectUser(user.id);
    window.location.reload();
  };

  const deleteUser = async () => {
    await postDestroyUser(user.id);
    window.location.replace("/admin/users");
  };

  const verifyUserIdentity = async () => {
    verifyIdentity(user.id);
  };

  const onClickDisonnect = () => {
    disconnectUserModalRef.current?.show();
  };

  const onClickDelete = () => {
    deleteUserModalRef.current?.show();
  };

  const onClickVerifyIdentity = () => {
    verifyUserIdentityModalRef.current?.show();
  };

  const onClickShipCard = () => {
    shipCardModalRef.current?.show();
  };

  const onSuccessShipCard = () => {
    refetchUser();
  };

  return (
    <Styled.SectionTile>
      <Heading.H4 tag="h2" color="cerise">
        The Danger Zone
      </Heading.H4>
      <UILabel.Large>
        Exercise caution when taking the following user actions.
      </UILabel.Large>
      <Styled.TileActions>
        <Button level={ButtonLevel.ghost} onClick={impersonateUser}>
          <Icon name="userSecret" />
          View as User
        </Button>

        {user.identityVerificationStatus === "flagged_for_review" && (
          <Button level={ButtonLevel.ghost} onClick={onClickVerifyIdentity}>
            <Icon name="glasses" />
            Manually Verify Identity
          </Button>
        )}

        {user.cards && !user.cards.physical && (
          <Button level={ButtonLevel.ghost} onClick={onClickShipCard}>
            <Icon name="creditCard" />
            Ship Physical Card
          </Button>
        )}

        <hr />

        <Styled.DangerGhostButton onClick={onClickDisonnect}>
          <Icon name="warningTriangle" />
          Disconnect user
        </Styled.DangerGhostButton>

        <Button level={ButtonLevel.danger} onClick={onClickDelete}>
          <Icon name="trash" />
          Delete user
        </Button>
      </Styled.TileActions>

      <ConfirmActionWithValidationModal
        ref={disconnectUserModalRef}
        title="Disconnect user"
        action={disconnectUser}
        actionLabel={`Disconnect ${user.name}`}
        validationString={`disconnect ${user.name}`}
        buttonLevel={ButtonLevel.danger}
      />
      <ConfirmActionWithValidationModal
        ref={deleteUserModalRef}
        title="Delete user"
        action={deleteUser}
        actionLabel={`Delete ${user.name}`}
        validationString={`delete ${user.name}`}
        buttonLevel={ButtonLevel.danger}
      />
      <ConfirmActionWithValidationModal
        ref={verifyUserIdentityModalRef}
        title="Manually verify user identity"
        action={verifyUserIdentity}
        actionLabel={`Verified ${user.name}`}
        validationString={`verified ${user.name}`}
        buttonLevel={ButtonLevel.danger}
      />
      <ShipCardModal
        ref={shipCardModalRef}
        onSuccess={onSuccessShipCard}
        id={user.id}
      />
    </Styled.SectionTile>
  );
};

export default Danger;
