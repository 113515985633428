import { motion } from "framer-motion";
import styled from "styled-components";
import { borderRadius, mediaMax, shadows, zIndex } from "utils/theme";

export const Wrapper = styled(motion.aside)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndex.modal + 1};

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: safe center;

  pointer-events: none;
`;

export const ModalWrapper = styled(motion.div)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-height: 100%;
  max-width: calc(100% - 24px);
  padding: 24px 0;
  overflow: auto;

  pointer-events: all;
`;

export const Close = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const CloseButton = styled.div`
  outline: 0;
  border: 0;
  appearance: none;
`;

export const ModalTile = styled.div`
  position: relative;

  width: 480px;
  max-width: 100%;
  min-height: 96px;
  padding: 48px;

  background: white;
  box-shadow: ${shadows.large};
  border-radius: ${borderRadius.large}px;

  text-align: center;

  pointer-events: all;

  ${mediaMax("md")} {
    padding: 36px;
  }
`;

export const Main = styled.div`
  margin: 16px 0;

  ${mediaMax("md")} {
    margin: 8px 0;
  }
`;
