import React from "react";
import { SplitBlock } from "types/cms";
import * as Styled from "./styled";
import Header from "../../Header";

const Split: React.FC<SplitBlock> = ({
  isHero,
  data: {
    heading,
    eyebrow,
    body,
    textSize,
    image,
    cta,
    imageOrientation = "left",
  },
}) => (
  <Styled.Wrapper>
    <Styled.HeaderContent $imageOrientation={imageOrientation}>
      <Styled.ImageWrapper>
        <Styled.Image>
          <img src={image} />
        </Styled.Image>
      </Styled.ImageWrapper>

      <Styled.HeaderText>
        <Header
          heading={heading}
          body={body}
          cta={cta}
          eyebrow={eyebrow}
          textSize={textSize}
          isHero={isHero}
        />
      </Styled.HeaderText>
    </Styled.HeaderContent>
  </Styled.Wrapper>
);

export default Split;
