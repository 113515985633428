import React from "react";
import styled, { css } from "styled-components";
import ContentLayout from "components/layout/ContentLayout";
import { Eyebrow } from "components/ui/Text";
import motleyFoolLogo from "assets/images/publications/motley-fool.svg";
import axiosLogo from "assets/images/publications/axios.svg";
import americanBankerLogo from "assets/images/publications/american-banker.svg";
import { colors, mediaMax } from "utils/theme";

const LOGOS = [
  {
    logo: americanBankerLogo,
    title: "American Banker",
    href: "https://www.americanbanker.com/news/how-a-minnesota-bank-is-jumping-into-the-earned-wage-access-market",
    height: 40,
  },
  {
    logo: axiosLogo,
    title: "Axios",
    href: "https://www.axios.com/pro/fintech-deals/2024/02/29/earned-wage-access-reset-2-million",
    height: 44,
  },
  {
    logo: motleyFoolLogo,
    title: "The Motley Fool",
    href: "https://www.fool.com/the-ascent/credit-cards/articles/this-unique-fintech-could-help-working-families-improve-their-personal-finances/",
    height: 76,
  },
];

const Wrapper = styled.div`
  padding: 56px 0 72px;

  ${mediaMax("lg")} {
    padding: 24px 0 96px;
    background: ${colors.azure};
  }
`;

const Inner = styled(ContentLayout)`
  display: flex;
  align-items: center;
  gap: 96px;

  * {
    flex: 0 0 auto;
  }

  ${mediaMax("xl")} {
    gap: 64px;
  }

  ${mediaMax("lg")} {
    flex-direction: column;
    gap: 36px;
  }
`;

const Logos = styled.div`
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mediaMax("md")} {
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px 36px;
  }
`;

const LogoImage = styled.img<{ $height: number }>`
  ${(props) => css`
    height: ${props.$height}px;

    ${mediaMax("lg")} {
      height: ${props.$height / 1.5}px;
    }
  `}
`;

const Publications = () => (
  <Wrapper>
    <Inner>
      <Eyebrow color="rgba(255, 255, 255, 0.7)">As seen in</Eyebrow>
      <Logos>
        {LOGOS.map((logo) => (
          <a
            key={logo.title}
            href={logo.href}
            target="_blank"
            rel="noopener"
            data-turbo="false"
          >
            <LogoImage src={logo.logo} alt={logo.title} $height={logo.height} />
          </a>
        ))}
      </Logos>
    </Inner>
  </Wrapper>
);

export default Publications;
