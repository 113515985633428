import styled from "styled-components";
import { colors, mediaMax } from "utils/theme";

export const Wrapper = styled.div`
  position: relative;

  padding: 48px 0 120px;

  * {
    color: white;
  }

  ${mediaMax("lg")} {
    padding: 0 0 80px;

    background: linear-gradient(
      to bottom,
      ${colors.azure},
      ${colors.blackthorn}
    );
  }
`;
