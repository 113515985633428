import styled from "styled-components";
import BaseTile from "components/ui/Tile";
import {
  Body as BaseBody,
  BodySmall as BaseBodySmall,
} from "components/ui/Text";
import { mediaMax } from "utils/theme";

export const FormStep = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const HeaderBody = styled(BaseBody)`
  opacity: 0.7;
`;

export const ImageContainer = styled.div`
  position: relative;

  padding-bottom: 50%;
  overflow: hidden;

  img {
    position: absolute;
    left: 0;
    top: 0;

    display: block;
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;
  }
`;

export const Tile = styled(BaseTile)`
  overflow: hidden;
  padding: 0;
`;

export const TileContent = styled.div`
  position: relative;

  display: grid;
  gap: 8px;
  --padding: 36px;
  padding: 0 var(--padding) var(--padding);

  ${mediaMax("lg")} {
    --padding: 24px;
  }
`;

export const TileBody = styled(BaseBodySmall)`
  opacity: 0.5;
`;
