const storePartnerParams = () => {
  const params = new URLSearchParams(window.location.search);
  let partner = params.get("partner");

  if (window.location.pathname === "/msufcu") {
    partner = "msufcu";
  }

  if (!partner) return [];

  sessionStorage.setItem("partner", partner);

  return ["partner"];
};

export default storePartnerParams;
