import React from "react";
import UILabel from "components/ui/UILabel";
import Icon, { IconName } from "components/ui/Icon";
import { FormTask } from "types/task";
import * as Styled from "./styled";

interface NavItem {
  text: string;
  onClick?: (e: React.MouseEvent) => void;
  href?: string;
}

interface Props {
  title?: string;
  icon?: IconName;
  navigation: NavItem[];
  currentStepIndex: number;
  steps: FormTask[];
}

const FormProgressHeader: React.FC<Props> = ({
  title,
  icon,
  navigation,
  currentStepIndex,
  steps,
}) => (
  <Styled.Wrapper>
    <Styled.Main>
      <Styled.MainContent>
        <Styled.Title>
          {icon && <Icon name={icon} color="flax" />}
          {title && <UILabel.Medium>{title}</UILabel.Medium>}
        </Styled.Title>

        <Styled.NavItems>
          {navigation?.map((navItem) => (
            <Styled.NavItem
              key={navItem.text}
              href={navItem.href || "#"}
              onClick={(e) => {
                if (navItem.onClick) {
                  e.preventDefault();
                  navItem.onClick(e);
                }
              }}
              data-turbo="false"
            >
              <UILabel.Medium bold>{navItem.text}</UILabel.Medium>
            </Styled.NavItem>
          ))}
        </Styled.NavItems>
      </Styled.MainContent>

      <Styled.Progress>
        <Styled.ProgressBar
          animate={{ width: `${(currentStepIndex / steps.length) * 100}%` }}
          initial={{ width: 0 }}
        />
      </Styled.Progress>
    </Styled.Main>

    <Styled.StepInfoWrapper>
      <Styled.StepInfo>
        <Styled.CurrentStep>
          <UILabel.Micro bold color="violet">
            {currentStepIndex + 1}
          </UILabel.Micro>
        </Styled.CurrentStep>

        <UILabel.Small wideCaps bold color="azure">
          {steps[currentStepIndex]?.title}
        </UILabel.Small>
      </Styled.StepInfo>
    </Styled.StepInfoWrapper>
  </Styled.Wrapper>
);

export default FormProgressHeader;
