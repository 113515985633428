import React from "react";
import styled from "styled-components";
import {
  borderRadius,
  colors,
  fontSizesUI,
  mediaMax,
  shadows,
} from "utils/theme";

const Wrapper = styled.div`
  position: relative;

  display: flex;
  padding: 16px 24px;
  width: 100%;
  gap: 8px;
  align-items: center;
  overflow: hidden;

  border-radius: ${borderRadius.small}px;
  background: white;
  box-shadow: ${shadows.tile};
  color: ${colors.midnight};

  font-size: ${fontSizesUI.md}px;
  text-decoration: none;

  &::before {
    content: "";

    position: absolute;
    left: 0;
    top: 0;

    height: 100%;
    width: 12px;

    background: ${colors.cerise};
  }

  ${mediaMax("md")} {
    padding: 12px 16px;
  }
`;

const FormMessage = ({ children }) => <Wrapper>{children}</Wrapper>;

export default FormMessage;
