import styled from "styled-components";
import { Body, Heading } from "components/ui/Text";

export const FormHeader = styled.header`
  display: grid;
  gap: 36px;
`;

export const FormTitle = styled(Heading.H2)`
  text-align: center;
`;

export const FormDescription = styled(Body)`
  text-align: center;
`;

export const PayrollRef = styled.div`
  position: absolute;

  opacity: 0;
  visibility: hidden;
`;

export const SectionHeading = styled.div`
  display: grid;
  gap: 12px;
`;
