import React from "react";
import { BodySmall, Eyebrow } from "components/ui/Text";
import styled from "styled-components";
import Button, { ButtonLevel, ButtonSize } from "components/ui/Button";
import { breakpoints, mediaMax } from "utils/theme";
import { getInfo } from "./utils";

const Wrapper = styled.div`
  height: 100%;
  max-width: calc(${breakpoints.md}px - 48px);
  margin: 0 auto;

  ${mediaMax("lg")} {
    height: auto;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ButtonWrapper = styled.div`
  padding-top: 24px;
`;

const SidebarInfo = ({ infoType, closeDrawer }) => {
  const info = getInfo(infoType);
  return info ? (
    <Wrapper>
      <Content>
        <Eyebrow>{info.heading}</Eyebrow>
        <BodySmall>{info.body}</BodySmall>
      </Content>

      <ButtonWrapper>
        <Button
          onClick={closeDrawer}
          fullWidth
          size={ButtonSize.md}
          level={ButtonLevel.secondary}
        >
          I understand
        </Button>
      </ButtonWrapper>
    </Wrapper>
  ) : null;
};

export default SidebarInfo;
