// These match the API's Disclosure.disclosure_types enum
export type ApiDisclosureType =
  | "card_agreement"
  | "deposit_account_agreement"
  | "bank_privacy_policy"
  | "ewa_terms_of_service"
  | "ach_authorization_agreement"
  | "terms_of_service"
  | "privacy_policy"
  | "e_sign";

export interface ApiDisclosure {
  id: string;
  disclosureType: ApiDisclosureType;
  path: string;
}

export interface ApiDisclosures {
  disclosures: ApiDisclosure[];
}

type DisclosurePathsByType = { [key in ApiDisclosureType]: string };

export const getDisclosurePathsByType = (
  disclosuresData: ApiDisclosures
): DisclosurePathsByType =>
  disclosuresData.disclosures.reduce(
    (acc, disclosure) => ({
      ...acc,
      [disclosure.disclosureType]: disclosure.path,
    }),
    {
      privacy_policy: "",
      card_agreement: "",
      deposit_account_agreement: "",
      bank_privacy_policy: "",
      ewa_terms_of_service: "",
      ach_authorization_agreement: "",
    } as DisclosurePathsByType
  );
