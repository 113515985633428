import React from "react";
import SidebarLayout from "components/layout/SidebarLayout";
import DrawerLayout from "components/layout/DrawerLayout";
import FormProgressSidebar from "components/ui/FormProgressSidebar";
import FormProgressHeader from "components/ui/FormProgressHeader";
import Loading from "components/ui/Loading";
import AppFooter from "components/shared/AppFooter";
import { FormTask, FormTaskStatus } from "types/task";
import { getUserProfile } from "store/api/apiSlice";
import CardApplicationStep from "./components/CardApplicationStep";
import { TaskName } from "./types";
import * as Styled from "./styled";

const TASK_MAP: {
  [name in TaskName]: {
    title: string;
    description?: React.ReactNode;
  };
} = {
  [TaskName.termsAcceptance]: {
    title: "Review terms",
  },
  [TaskName.identityVerification]: {
    title: "Verify identity",
  },
  [TaskName.depositSwitching]: {
    title: "Switch direct deposit",
  },
  [TaskName.repaymentAccountAcknowledgement]: {
    title: "Confirm repayment account",
  },
};

interface Props {
  tasks: {
    name: TaskName;
    state: FormTaskStatus;
  }[];
}

const CardApplication: React.FC<Props> = ({ tasks }) => {
  const { data: userProfile, isLoading } = getUserProfile();

  if (isLoading) {
    return (
      <Styled.LoadingWrapper>
        <Loading />
      </Styled.LoadingWrapper>
    );
  }

  const steps = tasks.map((task) => ({
    id: task.name,
    status: task.state,
    ...TASK_MAP[task.name],
  })) as FormTask[];

  const currentStepIndex = steps.findIndex(
    (step) => step.status === FormTaskStatus.ready
  );
  const currentStep = steps[currentStepIndex];

  const partnerSlug = userProfile?.partnerSlug;

  return (
    <SidebarLayout
      sidebar={
        <Styled.SidebarWrapper>
          <FormProgressSidebar
            title="Card application"
            steps={steps}
            navigation={
              userProfile?.eligibleForCard
                ? [
                    {
                      text: "Save & Exit",
                      href: "/dashboard",
                      icon: "arrowLeft",
                    },
                  ]
                : []
            }
            partnerSlug={partnerSlug}
          />
        </Styled.SidebarWrapper>
      }
    >
      <Styled.MobileHeaderWrapper>
        <FormProgressHeader
          currentStepIndex={currentStepIndex}
          steps={steps}
          icon="creditCard"
          title="Reset Card Application"
          navigation={
            userProfile?.eligibleForCard
              ? [
                  {
                    text: "Save & Exit",
                    href: "/dashboard",
                  },
                ]
              : []
          }
        />
      </Styled.MobileHeaderWrapper>

      <DrawerLayout>
        {(drawer) => (
          <Styled.ContentWrapper>
            <Styled.Content>
              <Styled.FormStep>
                <CardApplicationStep
                  id={currentStep.id as TaskName}
                  drawer={drawer}
                />
              </Styled.FormStep>

              <Styled.ContentFooter>
                <AppFooter />
              </Styled.ContentFooter>
            </Styled.Content>
          </Styled.ContentWrapper>
        )}
      </DrawerLayout>
    </SidebarLayout>
  );
};

export default CardApplication;
