import styled, { css } from "styled-components";
import { BlockBackground } from "types/cms";
import { colors, mediaMax, mediaMin } from "utils/theme";

export const ContentBlocks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;

  &:first-child {
    padding-top: 80px;
  }

  &:last-child {
    padding-bottom: 120px;
  }

  ${mediaMax("lg")} {
    gap: 64px;

    &:first-child {
      padding-top: 64px;
    }

    &:last-child {
      padding-bottom: 80px;
    }
  }
`;

const getBackgroundColor = (color: string) => {
  switch (color) {
    case BlockBackground.light:
      return `${colors.azure}18`;
    default:
      return color;
  }
};

export const ContentBlock = styled.div<{ $background: string }>`
  ${(props) =>
    props.$background &&
    css`
      padding: 80px 0;

      background: ${getBackgroundColor(props.$background)};

      &:first-child {
        margin-top: -80px;
      }

      ${mediaMax("lg")} {
        padding: 64px 0;

        &:first-child {
          margin-top: -64px;
        }
      }
    `}
`;

export const Footer = styled.footer`
  padding-bottom: 96px;
`;

export const Wrapper = styled.div<{
  $withSidebar?: boolean;
  $noLayout?: boolean;
}>`
  --color-background: ${colors.ice};

  display: flex;
  flex-direction: column;
  gap: 80px;
  min-height: calc(100vh - 72px);

  background: var(--color-background);

  ${mediaMax("lg")} {
    gap: 60px;
  }

  ${mediaMax("md")} {
    gap: 40px;
  }

  ${(props) =>
    props.$withSidebar &&
    css`
      --color-background: color-mix(in srgb, ${colors.azure} 3%, white);

      ${mediaMin("lg")} {
        margin: -48px;
      }
    `}

  ${(props) =>
    props.$noLayout &&
    css`
      min-height: 0;
      margin: 0 !important;

      background: none;

      ${ContentBlocks} {
        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    `}
`;
