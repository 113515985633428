import styled from "styled-components";
import { mediaMax, mediaMin } from "utils/theme";

export const SidebarWrapper = styled.div`
  height: 100%;

  ${mediaMax("lg")} {
    display: none;
  }
`;

export const MobileHeaderWrapper = styled.div`
  ${mediaMin("lg")} {
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1 auto;

  ${mediaMax("lg")} {
    padding-bottom: 108px;
  }
`;

export const Content = styled.div`
  --padding-x: 16px;

  margin: 0 auto;
  max-width: 576px;
  width: 100%;
  flex: 1 1 auto;
  padding: 24px var(--padding-x);

  ${mediaMin("lg")} {
    padding: 48px var(--padding-x);
  }
`;

export const FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;

  ${mediaMin("lg")} {
    display: none;
  }
`;

export const ContentFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 96px auto 0;

  ${mediaMax("lg")} {
    flex-direction: column-reverse;
  }
`;
