import React, { useRef, useState } from "react";
import FormContainer from "components/form";
import { fetchWithCredentials } from "utils/api/common";
import { EventType, trackEvent } from "utils/analytics";
import { toHash } from "utils/string";
import App from "App";
import Success from "./components/Success";
import * as Styled from "./styled";
import { Eligibility, IncomeType } from "./types";
import { DEFAULT_VALUES } from "./constants";
import { getInitialParams } from "./utils";
import SignupForm from "./components/SignupForm";

const DEFAULT_ELIGIBILITY = {
  payroll: Eligibility.indeterminate,
  bank: Eligibility.indeterminate,
};

const SignupView: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [eligibility, setEligibility] = useState<{
    payroll: Eligibility;
    bank: Eligibility;
  }>(DEFAULT_ELIGIBILITY);
  const [hasError, setHasError] = useState({
    bank: false,
    payroll: false,
  });
  const initialParams = useRef(getInitialParams());

  const isEligible = Object.values(eligibility).every(
    (v) => v === Eligibility.eligible
  );
  const isIncomplete = Object.values(eligibility).some(
    (v) => v === Eligibility.indeterminate
  );

  const onSubmit = async (values, { setError }) => {
    if (isIncomplete) {
      setHasError(
        Object.entries(eligibility).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value === Eligibility.indeterminate,
          }),
          { bank: false, payroll: false }
        )
      );
    } else {
      setHasError({ payroll: false, bank: false });
      setIsSubmitting(true);

      const user = {
        email: values.user.email,
        name: `${values.user.first_name} ${values.user.last_name}`,
      };
      const payroll = {
        payroll_provider_name:
          values.payroll.id === "other"
            ? values.payroll.other_name
            : values.payroll.name,
        pinwheel_id: values.payroll.id,
        income_type: values.questionnaire?.incomeType,
      };
      const bank = {
        bank_account_name:
          values.bank.id === "other"
            ? values.bank.other_name
            : values.bank.name,
        plaid_id: values.bank.id,
      };
      const timeAndAttendance =
        values.questionnaire?.incomeType === IncomeType.hourly &&
        values.time_and_attendance?.id
          ? {
              time_and_attendance_platform_pinwheel_id:
                values.time_and_attendance.id,
              time_and_attendance_platform_name:
                values.time_and_attendance.id === "other"
                  ? values.time_and_attendance.other_name
                  : values.time_and_attendance.name,
            }
          : {};

      const body = isEligible
        ? { ...values }
        : {
            ...user,
            ...payroll,
            ...bank,
            ...timeAndAttendance,
          };

      const res = await fetchWithCredentials(
        isEligible ? "/users" : "/qualifier",
        {
          method: "POST",
          credentials: "include",
          body: JSON.stringify(body),
        }
      );

      setIsSubmitting(false);

      if (res.ok) {
        const userId = await toHash(values.user.email);
        trackEvent(EventType.userSignupSubmitted, { user_id: userId });
        setIsSuccess(true);
      } else {
        const errors = await res.json();
        Object.entries(errors).forEach(([name, message]) =>
          setError(
            `user.${name}`,
            {
              type: "custom",
              message: message instanceof Array ? message.join(" ") : message,
            },
            { shouldFocus: true }
          )
        );
      }
    }
  };

  const updateEligibility = (key: "bank" | "payroll") => (value: Eligibility) =>
    setEligibility((s) => ({ ...s, [key]: value }));

  const updateHasError = (key: "bank" | "payroll") => (value: boolean) =>
    setHasError((s) => ({ ...s, [key]: value }));

  const resetForm = () => {
    setEligibility(DEFAULT_ELIGIBILITY);
    setIsSuccess(false);
  };

  return (
    <App>
      <Styled.Wrapper>
        <FormContainer onSubmit={onSubmit} defaultValues={DEFAULT_VALUES}>
          {!isSuccess ? (
            <SignupForm
              isSubmitting={isSubmitting}
              hasError={hasError}
              updateHasError={updateHasError}
              updateEligibility={updateEligibility}
              eligibility={eligibility}
              initialParams={initialParams.current}
            />
          ) : (
            <Success eligibility={eligibility} resetForm={resetForm} />
          )}
        </FormContainer>
      </Styled.Wrapper>
    </App>
  );
};

export default SignupView;
