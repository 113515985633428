import React, { useEffect, useRef, useState } from "react";
import { Heading } from "components/ui/Text";
import Button from "components/ui/Button";
import PlaidModal, {
  ModalApi as PlaidModalApi,
} from "components/shared/PlaidModal";
import bankImage from "assets/images/onboarding/bank.jpg";
import { EventType, trackEvent } from "utils/analytics";
import { getOnboardingStatus } from "store/api/apiSlice";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import * as Styled from "./styled";

const Bank = () => {
  const modalRef = useRef<PlaidModalApi>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [checkOnboardingStatus, setCheckOnboardingStatus] = useState(false);
  const { data: onboardingStatus } = getOnboardingStatus({
    skip: !checkOnboardingStatus,
    pollingInterval: 2000,
  });

  const openModal = () => {
    if (modalRef && modalRef.current) {
      modalRef.current.open();
    }
  };

  useEffect(() => {
    if (onboardingStatus?.complete) {
      window.location.reload();
    }
  }, [onboardingStatus]);

  return (
    <Styled.FormStep>
      <Styled.Header>
        <Heading.H2 tag="h1">Almost there!</Heading.H2>

        <Styled.HeaderBody>
          Just one more step to build your Dashboard.
        </Styled.HeaderBody>
      </Styled.Header>

      <Styled.Tile size="lg">
        <Styled.ImageContainer>
          <img
            src={bankImage}
            alt="Illustration of arrows pointing from a payroll system to a bank, with Reset in the middle"
          />
        </Styled.ImageContainer>
        <Styled.TileContent>
          <Heading.H4>Connect your checking account</Heading.H4>
          <Styled.TileBody>
            Please choose the account you use for direct deposit. We'll use it
            to help you track your balance and upcoming bills.
          </Styled.TileBody>
        </Styled.TileContent>
      </Styled.Tile>

      <MobileFixedFooter>
        <Button
          isLoading={isLoading || checkOnboardingStatus}
          onClick={openModal}
        >
          Link Checking Account
        </Button>
      </MobileFixedFooter>

      <PlaidModal
        ref={modalRef}
        onSubmit={() => {
          setIsLoading(true);
        }}
        onSuccess={() => {
          trackEvent(EventType.userSignupSuccessful, {
            eventCallback: () => setCheckOnboardingStatus(true),
          });
        }}
      />
    </Styled.FormStep>
  );
};

export default Bank;
