import styled from "styled-components";
import { Heading } from "components/ui/Text";

export const Wrapper = styled.div`
  display: grid;
  gap: 36px;
  padding: 64px 0;
`;

export const FormHeader = styled.header`
  display: grid;
  gap: 36px;
`;

export const FormTitle = styled(Heading.H2)`
  text-align: center;
`;
