import React, { forwardRef, useImperativeHandle, useState } from "react";
import { getToken, storeIdentifiers } from "utils/api/pinwheel";
import BasePinwheelModal, { LinkResult } from "@pinwheel/react-modal";

interface Props {
  onSuccess?: (result: LinkResult) => void;
  onSubmit?: (result: LinkResult) => void;
  isDds?: boolean;
}

export interface ModalApi {
  open: () => void;
}

const PinwheelModal = (
  { onSuccess, onSubmit, isDds }: Props,
  ref: React.ForwardedRef<unknown>
) => {
  const [linkToken, setLinkToken] = useState<string | undefined>();

  const open = async () => {
    const res = await getToken(isDds);
    if (res.success && res.link_token) {
      setLinkToken(res.link_token);
    }
  };

  const onLinkSuccess = async (result) => {
    if (onSubmit) {
      onSubmit(result);
    }

    await storeIdentifiers(result.accountId, result.platformId);

    if (onSuccess) {
      onSuccess(result);
    }
  };

  const onExit = () => {
    setLinkToken(undefined);
  };

  useImperativeHandle(ref, () => ({ open }));

  return linkToken ? (
    <BasePinwheelModal
      linkToken={linkToken}
      open={linkToken !== undefined}
      onExit={onExit}
      onSuccess={(result) => {
        onLinkSuccess(result);
      }}
    />
  ) : (
    <></>
  );
};

export default forwardRef(PinwheelModal);
