import React, { useState } from "react";
import Button, { ButtonLevel, ButtonSize } from "components/ui/Button";
import Icon from "components/ui/Icon";
import * as Styled from "./styled";

interface AppWindow {
  HubSpotConversations: any;
  hsConversationsSettings: { [key: string]: any };
  hsConversationsOnReady: Function[];
  RESET: {
    hubspot: {
      chatContainerId: string;
    };
  };
}

const ChatButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const app = window as unknown as AppWindow;

  const toggleChat = () => {
    const chat = app.HubSpotConversations;
    if (isOpen) {
      chat.widget.remove();
    } else {
      chat.widget.load();
      chat.widget.open();
    }
    setIsOpen((o) => !o);
  };

  return (
    <Styled.ButtonContainer $isOpen={isOpen}>
      <Button
        size={ButtonSize.md}
        onClick={() => toggleChat()}
        level={isOpen ? ButtonLevel.primary : ButtonLevel.tertiary}
      >
        <Icon name={isOpen ? "close" : "chat"} />
        <span>{isOpen ? "Close chat" : "Talk to us"}</span>
      </Button>

      <Styled.ChatContainer>
        <div id={app.RESET?.hubspot?.chatContainerId} />
      </Styled.ChatContainer>
    </Styled.ButtonContainer>
  );
};

export default ChatButton;
