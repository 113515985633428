import React from "react";
import App from "App";
import { Task } from "./types";
import OnboardingFlow from "./OnboardingFlow";

interface Props {
  tasks: Task[];
}

const OnboardingView: React.FC<Props> = ({ tasks }) => (
  <App withAuth>
    <OnboardingFlow tasks={tasks} />
  </App>
);

export default OnboardingView;
