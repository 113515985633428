import React from "react";
import { Body, Eyebrow, Heading } from "components/ui/Text";
import * as Styled from "./styled";
import { Eligibility } from "../../types";

interface Props {
  eligibility: { bank: Eligibility; payroll: Eligibility };
  resetForm: () => void;
}

const Success: React.FC<Props> = ({ eligibility, resetForm }) => {
  const isEligible = Object.values(eligibility).every(
    (v) => v === Eligibility.eligible
  );
  const ineligibleList = Object.entries(eligibility).reduce(
    (acc, [key, value]) => {
      if (value === Eligibility.eligible) {
        return acc;
      }
      let name = key;
      switch (key) {
        case "payroll":
          name = "payroll provider";
          break;
        default:
      }
      return [...acc, name];
    },
    []
  );

  const content = isEligible
    ? {
        eyebrow: "Welcome to Reset",
        heading: "We're excited to have you on board!",
        body: "We emailed you a link. Please click it to verify your email address and continue. If you don't see the email in your inbox, please check your spam folder.",
      }
    : {
        eyebrow: "Thanks for your submission",
        heading: "We've added you to the waitlist!",
        body: (
          <>
            <Body>
              Reset is not currently compatible with your{" "}
              {ineligibleList.join(" or ")}, but we'll email you when that
              changes.
            </Body>

            {eligibility.payroll === Eligibility.ineligible && (
              <>
                <Body>
                  If you have a second source of income, you can try signing up
                  again.
                </Body>
                <Styled.Link onClick={resetForm}>Back to sign up</Styled.Link>
              </>
            )}
          </>
        ),
      };

  return (
    <Styled.Tile>
      <Eyebrow>{content.eyebrow}</Eyebrow>

      <Heading.H2 tag="h1">{content.heading}</Heading.H2>

      {content.body}
    </Styled.Tile>
  );
};

export default Success;
