import * as process from "process";

export type Environment = "development" | "staging" | "production";

const resetEnv = process.env.RESET_ENV as Environment;

const enableDemoTriggers =
  process.env.ENABLE_DEMO_TRIGGERS === "true" && resetEnv !== "production";

const socureSdkKey = process.env.SOCURE_SDK_KEY;

export default {
  enableDemoTriggers,
  socureSdkKey,
  resetEnv,
};
