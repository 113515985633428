import React from "react";
import { TaskName } from "../../types";
import Payroll from "./Payroll";
import Bank from "./Bank";

const OnboardingStep: React.FC<{ id: TaskName }> = ({ id }) => {
  let step;

  switch (id) {
    case TaskName.payrollLinking:
      step = <Payroll />;
      break;
    case TaskName.bankLinking:
      step = <Bank />;
      break;
    default:
      step = <></>;
      break;
  }

  return step;
};

export default OnboardingStep;
