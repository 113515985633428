import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  z-index: 2;

  display: flex;
  height: 44px;
  justify-content: space-between;
  align-items: center;
`;

export const Actions = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;
