import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { InputField, SearchInputField } from "components/form";
import InfoBox from "components/ui/InfoBox";
import { searchInstitutions } from "utils/api/plaid";
import { SearchInputSelection } from "types/general";
import { Eligibility } from "../types";

const REQUIRED_PRODUCTS = ["auth", "transactions", "recurring_transactions"];

interface Props {
  initialValue?: SearchInputSelection;
  setEligibility: (eligibility: Eligibility) => void;
  setHasError: (hasError: boolean) => void;
}

interface Bank {
  name: string;
  id: string;
  data: {
    products: string[];
  };
}

const BankQuestionnaire: React.FC<Props> = ({
  initialValue,
  setEligibility,
  setHasError,
}) => {
  const [bank, setBank] = useState<Bank | undefined>(undefined);

  const { register, setValue } = useFormContext();
  register("bank");

  const onSelectBank = (item, searchValue) => {
    setValue("bank", item);
    setBank(item || undefined);
    if (item.id === "other") {
      setValue("bank.other_name", searchValue);
    }
    if (item) {
      setHasError(false);
    }
  };

  useEffect(() => {
    if (bank) {
      const eligibility = REQUIRED_PRODUCTS.every((product) =>
        bank.data.products.includes(product)
      )
        ? Eligibility.eligible
        : Eligibility.ineligible;
      setEligibility(eligibility);
    } else {
      setEligibility(Eligibility.indeterminate);
    }
  }, [bank]);

  return (
    <>
      <SearchInputField
        initialValue={initialValue}
        onSelect={onSelectBank}
        getSearch={searchInstitutions}
        label="Bank or credit union"
        errorMessage="Please select a bank from the list"
        name="bank_name"
        required
      />

      {bank?.id === "other" && (
        <InputField name="bank[other_name]" label="Other" />
      )}

      {process.env.NODE_ENV === "development" && bank?.data.products && (
        <div>{bank.data.products.join(", ")}</div>
      )}

      <InfoBox title="Important note">
        Select the bank or credit union where you have your primary checking
        account. Selecting a different one may cause problems with verifying
        your direct deposit.
      </InfoBox>
    </>
  );
};

export default BankQuestionnaire;
