import React from "react";
import { CardType, TileBlock } from "types/cms";
import * as Styled from "./styled";
import CtaButton from "../../CtaButton";
import IconImage from "../../IconImage";
import Header from "../../Header";

const Tile: React.FC<TileBlock> = ({
  isHero,
  data: { eyebrow, heading, body, textSize, tiles, cta },
}) => (
  <Styled.Wrapper>
    {(eyebrow || heading || body) && (
      <Header
        heading={heading}
        body={body}
        eyebrow={eyebrow}
        isCentered
        isHero={isHero}
        textSize={textSize}
      />
    )}
    <Styled.CardList>
      {tiles.map((tile, index) => (
        <Styled.Card key={index}>
          {tile.type === CardType.icon ? (
            <Styled.CardIcon>
              <IconImage {...tile} size="xl" color="white" />
            </Styled.CardIcon>
          ) : (
            <Styled.CardImage>
              <img src={tile.image} alt={tile.title || "Image"} />
            </Styled.CardImage>
          )}

          {(tile.title || tile.body) && (
            <Styled.CardContent>
              {tile.title && (
                <Styled.CardHeading>{tile.title}</Styled.CardHeading>
              )}
              {tile.body && <Styled.CardBody>{tile.body}</Styled.CardBody>}
            </Styled.CardContent>
          )}
        </Styled.Card>
      ))}
    </Styled.CardList>
    {cta && (
      <Styled.CtaWrapper>
        <CtaButton {...cta} />
      </Styled.CtaWrapper>
    )}
  </Styled.Wrapper>
);

export default Tile;
