import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Heading } from "components/ui/Text";
import Icon from "components/ui/Icon";
import UILabel from "components/ui/UILabel";
import { colors } from "utils/theme";
import { getUserProfile } from "store/api/apiSlice";
import * as Styled from "./styled";
import { getItems } from "./constants";
import Modal, { ModalApi } from "../Modal";

const DslZeroModal = (_: {}, forwardedRef: React.ForwardedRef<unknown>) => {
  const ref = useRef<ModalApi>(null);
  useImperativeHandle(forwardedRef, () => ref.current);
  const { data: userProfile } = getUserProfile();

  if (!userProfile) return null;

  const items = getItems(userProfile);

  return (
    <Modal ref={ref}>
      <Styled.Wrapper>
        <Heading.H3 tag="h2">Why is my spending limit&nbsp;$0?</Heading.H3>
        <Styled.List>
          {items.map((item) => (
            <Styled.ListItem key={item.icon}>
              <Styled.IconWrapper>
                <Icon name={item.icon} color="violet" />
              </Styled.IconWrapper>

              <Styled.ItemText>
                <UILabel.Large bold>{item.title}</UILabel.Large>
                <UILabel.Medium color={`${colors.midnight}CC`}>
                  {item.body}
                </UILabel.Medium>
              </Styled.ItemText>
            </Styled.ListItem>
          ))}
        </Styled.List>
      </Styled.Wrapper>
    </Modal>
  );
};

export default forwardRef(DslZeroModal);
