import React from "react";
import styled, { css } from "styled-components";
import {
  LinkProps as BaseRouterLinkProps,
  Link as RouterLink,
} from "react-router-dom";
import LoadingDots from "components/ui/LoadingDots";
import { colors, fontSizesUI, fontWeights, fonts } from "utils/theme";
import Icon from "./Icon";

export enum ButtonSize {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
}

export enum ButtonLevel {
  primary = "primary",
  secondary = "secondary",
  tertiary = "tertiary",
  cta = "cta",
  danger = "danger",
  ghost = "ghost",
  success = "success",
}

interface StyledProps {
  $isLoading?: boolean;
  $isSuccess?: boolean;
  $fullWidth?: boolean;
  $size: ButtonSize;
  $level: ButtonLevel;
}

const getSizeTheme = (size) => {
  switch (size) {
    case ButtonSize.xl:
      return `
        padding: 8px 48px;
        height: 60px;
        font-size: ${fontSizesUI.xl}px;
      `;
    case ButtonSize.lg:
      return `
        padding: 8px 36px;
        height: 52px;
        font-size: ${fontSizesUI.lg}px;
      `;
    case ButtonSize.md:
      return `
        padding: 8px 24px;
        height: 44px;
        font-size: ${fontSizesUI.md}px;
      `;
    case ButtonSize.sm:
      return `
        padding: 4px 20px;
        height: 36px;
        font-size: ${fontSizesUI.sm}px;
      `;
    case ButtonSize.xs:
      return `
        padding: 4px 8px;
        height: 28px;
        border-radius: 4px;
        font-size: ${fontSizesUI.sm}px;
      `;
    default:
      return "";
  }
};

const getLevelTheme = (level) => {
  switch (level) {
    case ButtonLevel.primary:
      return `
        background: ${colors.midnight};
        border: none;
        --color: white;
      `;
    case ButtonLevel.tertiary:
      return `
        background: color-mix(in srgb, ${colors.azure} 20%, ${colors.ice});
        border: none;
        --color: ${colors.midnight};
      `;
    case ButtonLevel.cta:
      return `
        background: ${colors.violet};
        border: none;
        --color: ${colors.white};
      `;
    case ButtonLevel.danger:
      return `
        background: ${colors.cerise};
        border: none;
        --color: ${colors.white};
      `;
    case ButtonLevel.secondary:
    case ButtonLevel.ghost:
      return `
        background: transparent;
        border: 1.5px solid ${colors.midnight};
        --color: ${colors.midnight};
      `;
    default:
      return "";
  }
};

const getTheme = (props: StyledProps) => `
    ${getSizeTheme(props.$size)}
    ${getLevelTheme(props.$level)}
  `;

export const BaseButton = styled.button`
  padding: 0;

  appearance: none;
  cursor: pointer;
  border: 0;
  background: none;
  color: inherit;

  font-family: ${fonts.body};
  text-align: center;
`;

const Loading = styled(LoadingDots)<{ size: ButtonSize }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${(props) =>
    [ButtonSize.xs, ButtonSize.sm].includes(props.size) &&
    css`
      transform: scale(0.5) translate(-50%, -50%);
      transform-origin: center;
    `};
`;

const Success = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 1.25em;
  width: 1.25em;

  stroke: currentColor;
  stroke-width: 1em;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const StyledButton = styled(BaseButton)<StyledProps>`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 9999px;
  color: var(--color);

  font-weight: ${fontWeights.bold};
  text-decoration: none;

  ${(props) => getTheme(props)}

  ${(props) =>
    !props.$isSuccess &&
    css`
      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
      }
    `}

  &:visited {
    color: var(--color);
  }

  ${(props) =>
    props.$isLoading
      ? css`
          opacity: 0.5;
          cursor: not-allowed;
          pointer-events: none;

          ${Content} {
            visibility: hidden;
            opacity: 0;
          }
        `
      : ""}

  ${(props) =>
    props.$isSuccess
      ? css`
          --color: ${colors.midnight};
          background: ${colors.leaf};
          border: none;
          cursor: not-allowed;
          pointer-events: none;

          ${Content} {
            visibility: hidden;
            opacity: 0;
          }
        `
      : ""}

  ${(props) =>
    props.$fullWidth
      ? `
  width: 100%;
  `
      : ""}
`;

interface BaseProps {
  as?: React.ElementType;
  isLoading?: boolean;
  isSuccess?: boolean;
  fullWidth?: boolean;
  size?: ButtonSize;
  level?: ButtonLevel;
  disabled?: boolean;
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    BaseProps {}

export interface LinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    BaseProps {}

export interface RouterLinkProps extends BaseRouterLinkProps, BaseProps {}

const RootButton: React.FC<
  BaseProps & { component?: string | React.FC<any>; children?: any }
> = ({
  isLoading,
  isSuccess,
  fullWidth,
  disabled,
  children,
  size = ButtonSize.lg,
  level = ButtonLevel.primary,
  component,
  ...props
}) => (
  <StyledButton
    as={component}
    disabled={isLoading || disabled}
    $isLoading={isLoading}
    $isSuccess={isSuccess}
    $fullWidth={fullWidth}
    $size={size}
    $level={level}
    {...props}
  >
    {isLoading && <Loading size={size} />}
    {isSuccess && <Success name="checkmark" />}
    <Content>{children}</Content>
  </StyledButton>
);

const Button: React.FC<ButtonProps> = (props) => <RootButton {...props} />;

export const ButtonLink: React.FC<LinkProps> = (props) => (
  <RootButton component="a" data-turbo="false" {...props} />
);

export const ButtonRouterLink: React.FC<RouterLinkProps> = (props) => (
  <RootButton component={RouterLink} data-turbo="false" {...props} />
);

export default Button;
