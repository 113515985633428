import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  InputField,
  SearchInputField,
  SelectField,
  RadioField,
} from "components/form";
import {
  searchInstitutions,
  searchTimeAndAttendance,
} from "utils/api/pinwheel";
import { SearchInputSelection } from "types/general";
import { Eligibility } from "../types";
import { DEFAULT_VALUES, INCOME_OPTIONS } from "../constants";
import { CONDITIONS, pathIncludes } from "../utils";

interface Props {
  initialValue?: SearchInputSelection;
  setEligibility: (eligibility: Eligibility) => void;
  setHasError: (hasError: boolean) => void;
}

interface PayrollProvider {
  name: string;
  id: string;
  data: {
    products: string[];
  };
}

const PayrollQuestionnaire: React.FC<Props> = ({
  initialValue,
  setEligibility,
  setHasError,
}) => {
  const [payroll, setPayroll] = useState<PayrollProvider | undefined>(
    undefined
  );
  const [timeAndAttendance, setTimeAndAttendance] = useState<
    PayrollProvider | undefined
  >(undefined);
  const [path, setPath] = useState<string[]>(["root"]);

  const { register, setValue, getValues, watch } = useFormContext();
  register("payroll");
  register("time_and_attendance");
  const incomeInput = watch("questionnaire.incomeType");
  const trackHoursInput = watch("questionnaire.tracksHoursWithPayroll");

  const onSelectPayroll = (item, searchValue) => {
    setValue("payroll", item);
    setPayroll(item || undefined);
    if (item.id === "other") {
      setValue("payroll.other_name", searchValue);
    }
    if (item) {
      setHasError(false);
    }
  };

  const onSelectTimeAndAttendance = (item, searchValue) => {
    setValue("time_and_attendance", item);
    setTimeAndAttendance(item || undefined);
    if (item.id === "other") {
      setValue("time_and_attendance.other_name", searchValue);
    }
    if (item) {
      setHasError(false);
    }
  };

  const updateEligibility = () => {
    let outcome;
    const currentStep = ["root"];
    while (
      ![
        Eligibility.eligible,
        Eligibility.ineligible,
        Eligibility.indeterminate,
      ].includes(outcome)
    ) {
      const condition = CONDITIONS[currentStep.join(".")];
      if (condition) {
        outcome = condition({ products: payroll?.data.products, getValues });
        currentStep.push(outcome);
      } else {
        outcome = Eligibility.indeterminate;
      }
    }
    setPath(currentStep);
    setEligibility(outcome);
  };

  useEffect(() => {
    updateEligibility();
  }, [payroll, incomeInput, trackHoursInput]);

  useEffect(() => {
    setValue("questionnaire", DEFAULT_VALUES.questionnaire);
    setTimeAndAttendance(undefined);
  }, [payroll]);

  return (
    <>
      <SearchInputField
        initialValue={initialValue}
        onSelect={onSelectPayroll}
        getSearch={searchInstitutions}
        label="Employer or payroll provider"
        errorMessage="Please select a provider from the list"
        name="payroll_name"
        required
      />

      {payroll?.id === "other" && (
        <InputField name="payroll[other_name]" label="Other" required />
      )}

      {process.env.NODE_ENV === "development" && payroll?.data.products && (
        <div>{payroll.data.products.join(", ")}</div>
      )}

      {pathIncludes(path, "root.yes") && (
        <SelectField
          name="questionnaire.incomeType"
          label="Income type"
          options={INCOME_OPTIONS}
          required
        />
      )}

      {pathIncludes(path, "root.yes.hourly") && (
        <RadioField
          name="questionnaire.tracksHoursWithPayroll"
          label="Where do you log your hours?"
          required
          options={[
            {
              value: "yes",
              label: (
                <>
                  I log my hours with{" "}
                  <strong>{getValues("payroll.name")}</strong>
                </>
              ),
            },
            {
              value: "no",
              label: (
                <>
                  I log my hours with a <strong>different platform</strong>
                </>
              ),
            },
          ]}
        />
      )}

      {pathIncludes(path, "root.yes.hourly.no") && (
        <>
          <SearchInputField
            onSelect={onSelectTimeAndAttendance}
            getSearch={searchTimeAndAttendance}
            label="Time and attendance platform"
            errorMessage="Please select a platform from the list"
            name="time_and_attendance_name"
            required
          />

          {timeAndAttendance?.id === "other" && (
            <InputField
              name="time_and_attendance[other_name]"
              label="Other"
              required
            />
          )}
        </>
      )}
    </>
  );
};

export default PayrollQuestionnaire;
