import styled, { keyframes } from "styled-components";
import ContentLayout from "components/layout/ContentLayout";
import { Eyebrow as BaseEyebrow } from "components/ui/Text";
import { colors, mediaMax, mediaMin } from "utils/theme";
import { ImageOrientation } from "types/cms";
import BaseHeader from "../../Header";

export const BackgroundShape = styled.div`
  position: absolute;
  bottom: -1px;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
  width: 100%;
  height: 100%;
  overflow: hidden;

  pointer-events: none;

  svg {
    flex: 0 0 auto;
    width: 100%;
    min-width: 2200px;
  }
`;

export const HeaderContent = styled(ContentLayout)`
  position: relative;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
  margin: 0 auto;
  padding-top: 64px;
  align-items: center;

  sup {
    color: color-mix(in srgb, var(--color) 50%, transparent);

    line-height: 1;
    font-size: 0.66em;
  }
`;

export const Eyebrow = styled(BaseEyebrow)`
  color: var(--color-eyebrow);
`;

export const CardContainer = styled.div`
  position: relative;
  z-index: 1;

  display: flex;
  justify-content: center;
`;

export const aFloat = keyframes`
  0% {
    transform: translateY(-2%);
  }
  100% {
    transform: translateY(2%);
  }
`;

export const aShadow = keyframes`
  0% {
    transform: scale(1.2);

    opacity: 0.075;
  }
  100% {
    transform: scale(1);

    opacity: 0.15;
  }
`;

export const CardImage = styled.div`
  position: relative;

  img {
    display: block;
    height: 520px;

    animation: ${aFloat} 2s ease-in-out alternate forwards infinite;
  }
`;

export const CardShadow = styled.div`
  position: relative;
  transform: scaleX(1.1) scaleY(0.2);

  filter: blur(32px);

  &::after {
    content: "";

    display: block;
    width: 66%;
    height: 134px;
    margin: 12px auto -24px;

    background: ${colors.violet};
    border-radius: 9999px;

    animation: ${aShadow} 2s ease-in-out alternate forwards infinite;
    will-change: transform, opacity;
  }
`;

export const Header = styled(BaseHeader)`
  padding-top: 24px;
  padding-bottom: 180px;
`;

interface WrapperProps {
  $imageOrientation: ImageOrientation;
  $theme?: "default" | "light";
}

const getStyles = ({ $imageOrientation, $theme = "default" }: WrapperProps) => {
  const styles = [];
  if ($imageOrientation === "right") {
    styles.push(`
        ${mediaMin("lg")} {
          ${HeaderContent} {
            ${Header} {
              order: -1;
            }
          }
    
          ${BackgroundShape} {
            transform: scaleX(-1);
          }
        }
    `);
  }
  switch ($theme) {
    case "light":
      styles.push(`
        background: color-mix(in srgb, ${colors.azure} 10%, ${colors.ice});
      `);
      break;
    case "default":
    default:
      styles.push(`
        background: linear-gradient(75deg, ${colors.heliotrope}, ${colors.azure});
      `);
  }
  return styles.join("");
};

export const Wrapper = styled.header<WrapperProps>`
  position: relative;

  & + * {
    margin-top: 0 !important;
  }

  ${mediaMax("lg")} {
    padding-bottom: 60px;
    margin-bottom: -120px;

    ${CardShadow} {
      display: none;
    }

    & + * {
      position: relative;
      z-index: 1;

      margin-top: -120px;
    }

    ${HeaderContent} {
      display: flex;
      flex-direction: column-reverse;
      gap: 64px;
      padding-top: 24px;
      padding-bottom: 24px;

      text-align: center;
    }

    ${Header} {
      width: 100%;
      padding-bottom: 0;
    }

    ${BackgroundShape} {
      z-index: 1;

      flex-direction: column;
      align-items: center;
      justify-content: flex-end;

      &::after {
        content: "";

        display: block;
        width: 100%;
        margin-top: -1px;
        height: 240px;
        flex: 0 0 auto;

        background: ${colors.ice};
      }

      svg {
        min-width: 1440px;
      }
    }

    ${CardContainer} {
      margin-bottom: 96px;
    }

    ${CardImage} {
      img {
        height: 400px;
        max-width: 100%;

        animation: none;
      }
    }
  }

  ${(props) => getStyles(props)}
`;
