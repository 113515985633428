import styled from "styled-components";
import ContentLayout from "components/layout/ContentLayout";
import { Eyebrow } from "components/ui/Text";
import { borderRadius, colors, mediaMax, mediaMin, shadows } from "utils/theme";

export const BackgroundShape = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) scaleX(-1);

  width: 100%;
  min-width: 2200px;
`;

export const BackgroundShapeMobile = styled.img`
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -144px);

  width: 1024px;
  max-width: none;
`;

export const NotificationTile = styled.div`
  display: grid;
  width: 320px;
  grid-template-columns: 40px 1fr;
  gap: 12px;
  padding: 16px;

  border-radius: ${borderRadius.small}px;
  background: white;
  box-shadow: ${shadows.tileSolid};

  text-align: left;
`;

export const NotificationIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  background: linear-gradient(45deg, ${colors.heliotrope}, ${colors.azure});
  border-radius: 8px;

  img {
    height: 24px;
    display: block;
  }
`;

export const NotificationText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const HeaderContent = styled(ContentLayout)`
  position: relative;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
  margin: 0 auto;
  padding-top: 96px;
`;

export const CardWrapper = styled.div`
  position: relative;

  display: flex;
  justify-content: flex-end;

  ${mediaMax("lg")} {
    justify-content: center;

    ${NotificationTile} {
      display: none;
    }
  }

  ${mediaMin("lg")} {
    ${NotificationTile} {
      position: absolute;
      top: 20%;
      right: 120px;
    }
  }
`;

export const CardImage = styled.div`
  position: relative;

  img {
    display: block;
    width: 360px;
  }
`;

export const CardShadow = styled.div`
  position: relative;
  transform: scaleY(0.2);

  filter: blur(32px);

  &::after {
    content: "";

    display: block;
    width: 192px;
    height: 134px;
    margin: 12px auto 0;

    background: ${colors.violet};
    border-radius: 9999px;
    opacity: 0.2;
  }
`;

export const Tag = styled(Eyebrow)`
  width: fit-content;
  padding: 4px 8px;

  background: ${colors.heliotrope};
  border-radius: 4px;
  color: white;

  line-height: 1;
`;

export const HeaderCta = styled.div`
  padding-top: 24px;
  width: 320px;
  max-width: 100%;

  a {
    width: 100%;
  }

  ${mediaMax("md")} {
    width: 100%;
  }
`;

export const HeaderText = styled.div`
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${mediaMin("lg")} {
    ${NotificationTile} {
      display: none;
    }
  }

  ${mediaMax("lg")} {
    align-items: center;

    ${NotificationTile} {
      margin: 0 auto 24px;
    }
  }
`;

export const Bullets = styled.ul`
  display: grid;
  gap: 6px;
  padding: 12px 0 4px;
  margin: 0;

  list-style-type: none;
  text-align: left;

  ${mediaMax("md")} {
    padding: 12px 12px 4px;
  }

  ${mediaMin("lg")} {
    margin-right: -80px;
  }
`;

export const Bullet = styled.li`
  display: flex;
  gap: 12px;
`;

export const BulletIcon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;

  svg {
    display: block;
  }

  ${mediaMax("lg")} {
    height: 26px;
  }
`;

export const Wrapper = styled.header`
  position: relative;

  overflow: hidden;

  ${mediaMin("lg")} {
    ${BackgroundShapeMobile} {
      display: none;
    }
  }

  ${mediaMax("lg")} {
    padding-bottom: 48px;

    background: ${colors.ice};

    ${HeaderContent} {
      display: flex;
      flex-direction: column;
      gap: 64px;
      padding-top: 24px;

      text-align: center;
    }

    ${BackgroundShape} {
      display: none;
    }

    ${HeaderCta} {
      a {
        margin: 0 auto;
      }
    }

    ${CardImage} {
      z-index: 1;

      img {
        width: 240px;

        animation: none;
      }
    }

    ${CardShadow} {
      display: none;
    }
  }

  ${mediaMax("md")} {
    ${BackgroundShapeMobile} {
      width: 100%;
    }
  }
`;
