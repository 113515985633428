import React, { useEffect, useState } from "react";
import { useScript } from "@uidotdev/usehooks";
import { getUserProfile } from "store/api/apiSlice";
import environment from "utils/environment";

interface Marqeta {
  bootstrap: (props: { clientAccessToken: string; [key: string]: any }) => void;
}

interface Window {
  marqeta: Marqeta;
}

export interface MarqetaApi {
  hide: () => void;
  show: () => void;
  detailsAreShown: boolean;
  id: string;
  isLoading: boolean;
}

type DetailType = "cardPan" | "cardExp" | "cardCvv";

const DEMO_DATA = {
  cardPan: "0123 4567 8901 2345",
  cardExp: "12/28",
  cardCvv: "098",
};

export type MarqetaIds = { [name in DetailType]: string };

interface Props {
  children: (props: MarqetaApi) => React.ReactNode;
  ids: MarqetaIds;
  styles: { [name in DetailType]: { [key: string]: string } };
}

const MarqetaContainer: React.FC<Props> = ({ ids, styles, children }) => {
  const [detailsAreShown, setDetailsAreShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { data: user, isFetching, refetch, requestId } = getUserProfile();
  const scriptStatus = useScript(
    "https://widgets.marqeta.com/marqetajs/2.0.0/marqeta.min.js",
    {
      removeOnUnmount: true,
    }
  );
  const clientAccessToken = user?.currentCardAccount?.clientToken?.clientToken;

  const show = () => {
    setIsLoading(true);
    refetch();
    setDetailsAreShown(true);
  };

  const hide = () => {
    setDetailsAreShown(false);
  };

  const showDemoCardDetails = () => {
    Object.entries(ids).forEach(([key, id]) => {
      const element = document.getElementById(id);
      if (!element) return;

      element.innerHTML = DEMO_DATA[key as DetailType];
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (
      environment.enableDemoTriggers &&
      detailsAreShown &&
      !clientAccessToken
    ) {
      showDemoCardDetails();
      return;
    }

    if (
      scriptStatus === "ready" &&
      clientAccessToken &&
      detailsAreShown &&
      !isFetching
    ) {
      const { marqeta } = window as unknown as Window;
      marqeta.bootstrap({
        clientAccessToken,
        integrationType: "custom",
        component: {
          showPan: {
            cardPan: {
              domId: ids.cardPan,
              format: true,
              styles: { span: styles.cardPan },
            },
            cardExp: {
              domId: ids.cardExp,
              format: true,
              styles: { span: styles.cardExp },
            },
            cardCvv: { domId: ids.cardCvv, styles: { span: styles.cardCvv } },
          },
        },
        callbackEvents: {
          onSuccess: () => {
            setIsLoading(false);
          },
          onFailure: () => {
            setIsLoading(false);
          },
        },
      });
    }
  }, [scriptStatus, user, detailsAreShown]);

  return children({ hide, show, detailsAreShown, id: requestId, isLoading });
};

export default MarqetaContainer;
