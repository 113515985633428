import styled from "styled-components";
import ContentLayout from "components/layout/ContentLayout";
import { colors, fonts, mediaMin, mediaMax, shadows } from "utils/theme";

export const Wrapper = styled.nav`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;

  display: flex;
  align-items: center;
  width: 100%;
  height: 72px;
  margin: 0 auto;

  background: white;
  box-shadow: ${shadows.large};
`;

export const NavInner = styled(ContentLayout)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 36px;

  ${mediaMax("lg")} {
    max-width: none;
    gap: 16px;
  }
`;

export const Menu = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
`;

export const NavLeft = styled.div`
  display: flex;
  align-items: center;

  ${mediaMax("lg")} {
    display: none;
  }
`;

export const LogoWrapper = styled.div`
  width: 80px;

  svg {
    display: block;
    width: 100%;
  }

  ${mediaMax("lg")} {
    width: 72px;
  }
`;

export const NavItems = styled.ul`
  display: flex;
  gap: 24px;

  ${mediaMax("lg")} {
    flex-direction: row;
    gap: 16px;
  }
`;

export const NavItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;

  list-style-type: none;
`;

export const NavLink = styled.a`
  display: flex;
  gap: 2px;
  flex-direction: column;
  align-items: center;

  color: ${colors.midnight};

  font-family: ${fonts.body};
  text-decoration: none;

  &:visited {
    color: ${colors.midnight};
  }

  ${mediaMin("lg")} {
    flex-direction: row;
    gap: 6px;
  }
`;

export const NavRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  flex: 1 1 auto;
`;

export const MobileMenuWrapper = styled.div`
  display: none;

  ${mediaMax("lg")} {
    display: block;
  }
`;
