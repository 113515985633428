import React from "react";
import ContentLayout from "components/layout/ContentLayout";
import Split from "components/cms/components/block/Split";
import * as Styled from "./styled";
import { UNLOCK_YOUR_PAYCHECK } from "../../constants";

const UnlockYourPaycheck = () => (
  <Styled.Wrapper>
    <ContentLayout>
      <Split {...UNLOCK_YOUR_PAYCHECK} />
    </ContentLayout>
  </Styled.Wrapper>
);

export default UnlockYourPaycheck;
