import React, { useRef, useState } from "react";
import { Heading } from "components/ui/Text";
import Button from "components/ui/Button";
import PinwheelModal, {
  ModalApi as PinwheelModalApi,
} from "components/shared/PinwheelModal";
import payrollImage from "assets/images/onboarding/payroll.jpg";
import Notification from "components/ui/Notification";
import { useFlags } from "launchdarkly-react-client-sdk";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import * as Styled from "./styled";

const Payroll = () => {
  const flags = useFlags();
  const modalRef = useRef<PinwheelModalApi>(null);
  const [isLoading, setIsLoading] = useState(false);

  const openModal = () => {
    if (modalRef && modalRef.current) {
      modalRef.current.open();
    }
  };

  return (
    <Styled.FormStep>
      {!flags["rf-6-card-launch"] && (
        <Notification showIcon={false}>
          The Reset card is <u>coming soon</u>. App users will have early access
          to apply for the card when it is available.
        </Notification>
      )}

      <Styled.Header>
        <Heading.H2 tag="h1">Welcome to Reset!</Heading.H2>

        <Styled.HeaderBody>
          Let's finish setting up your Reset Dashboard.
        </Styled.HeaderBody>
      </Styled.Header>

      <Styled.Tile size="lg">
        <Styled.ImageContainer>
          <img
            src={payrollImage}
            alt="Illustration of an arrow pointing from a payroll system to Reset"
          />
        </Styled.ImageContainer>
        <Styled.TileContent>
          <Heading.H4>Connect your payroll account</Heading.H4>
          <Styled.TileBody>
            This allows us to show you continually updated data about your
            earnings.
          </Styled.TileBody>
        </Styled.TileContent>
      </Styled.Tile>

      <MobileFixedFooter>
        <Button
          isLoading={isLoading}
          onClick={openModal}
          data-test="btn-link-payroll"
        >
          Link Payroll
        </Button>
      </MobileFixedFooter>

      <PinwheelModal
        ref={modalRef}
        onSubmit={() => {
          setIsLoading(true);
        }}
        onSuccess={() => {
          window.location.reload();
        }}
      />
    </Styled.FormStep>
  );
};

export default Payroll;
