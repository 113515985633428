import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  text-align: left;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding: 0;
  margin: 0;

  list-style-type: none;
`;

export const ListItem = styled.li`
  display: flex;
  width: 100%;
  gap: 12px;
  align-items: flex-start;
`;

export const IconWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 20px;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
`;

export const ItemText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1 1 auto;
`;
