import React from "react";
import { FormTaskStatus } from "types/task";
import App from "App";
import { TaskName } from "./types";
import CardApplication from "./CardApplication";

interface Props {
  tasks: {
    name: TaskName;
    state: FormTaskStatus;
  }[];
}

const ApplyView: React.FC<Props> = ({ tasks }) => (
  <App withAuth>
    <CardApplication tasks={tasks} />
  </App>
);

export default ApplyView;
