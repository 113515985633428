import React from "react";
import msufcuLogo from "assets/images/partners/msufcu.svg";
import msufcuLogoReverse from "assets/images/partners/msufcu-reverse.svg";
import { Partner } from "./types";
import MsufcuBannerMessage from "./MsufcuBannerMessage";

export const PARTNERS: {
  [key: string]: Partner;
} = {
  msufcu: {
    name: "MSUFCU",
    enableProbationaryPeriod: false,
    logo: { color: msufcuLogo, reverse: msufcuLogoReverse },
    banner: {
      message: <MsufcuBannerMessage />,
      theme: {
        color: "#FFFFFF",
        background: "#004F38",
      },
    },
    signup: {
      bank: { searchString: "michigan state", id: "ins_105483" },
    },
    faqs: {
      identityVerification: [
        {
          title: "Why does Reset need this information?",
          body: "The Reset card application requires a separate identity verification than the one for opening an MSUFCU account.",
        },
      ],
    },
  },
};
