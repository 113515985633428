import React from "react";
import { usePostInternalMessageMutation } from "store/api/apiSlice";
import FormContainer, {
  AddressField,
  FormSection,
  InputField,
  MaskType,
  MaskedField,
  SubmitButton,
} from "components/form";
import { Heading } from "components/ui/Text";
import { ApiUserProfile } from "utils/api/user";
import { getMessage, getDefaultValues, getSections } from "./utils";

const FIELDS = {
  firstName: (
    <InputField name="firstName" label="First name" placeholder="Val" />
  ),
  lastName: (
    <InputField label="Last name" name="lastName" placeholder="Clark" />
  ),
  email: (
    <InputField
      name="email"
      label="Email"
      placeholder="val.clark@example.com"
      autoComplete="email"
      type="email"
    />
  ),
  phoneNumber: (
    <MaskedField
      maskType={MaskType.phone}
      placeholder="(123) 456-7890"
      name="phoneNumber"
      label="Phone Number"
    />
  ),
  currentAddress: <AddressField name="currentAddress" required />,
};

interface Props {
  userProfile: ApiUserProfile;
  setIsEditing: (isEditing: boolean) => void;
  setIsSuccess: (isSuccess: boolean) => void;
  setPersonalInfo: (info: ApiUserProfile) => void;
}

const Edit: React.FC<Props> = ({
  userProfile,
  setIsEditing,
  setIsSuccess,
  setPersonalInfo,
}) => {
  const [postInternalMessage] = usePostInternalMessageMutation();

  const defaultValues = getDefaultValues(userProfile);
  const sections = getSections(userProfile);

  const onSubmit = async (values) => {
    const message = getMessage(values, defaultValues);

    if (message.length > 0) {
      const body = ["Please update:", message].join("\n");
      await postInternalMessage({ type: "Update Personal Info", body });
      setPersonalInfo({ ...userProfile, ...values });
      setIsSuccess(true);
    }

    setIsEditing(false);
  };

  return (
    <FormContainer onSubmit={onSubmit} defaultValues={defaultValues}>
      <Heading.H3 tag="h1">Update your information</Heading.H3>

      {sections.map((section) => (
        <FormSection key={section.name}>
          <Heading.H4>{section.title}</Heading.H4>
          {section.fields.map((field, index) => (
            <React.Fragment key={index}>{FIELDS[field]}</React.Fragment>
          ))}
        </FormSection>
      ))}

      <SubmitButton>Submit</SubmitButton>
    </FormContainer>
  );
};

export default Edit;
